import { $ } from "../third-party-imports";
import { stopLoader } from "./stopLoader";
import {daysInMonth} from './daysInMonth';
import {dateDisplay, defaultEnableCal} from './dateDisplay';
import { apiConfig } from "../config/apiConfig";
import { getBrandJsonValue, getWeekObj, servieModal } from "./get-brands-json";
import { callBackComponent } from './callBackComponent';
import axios from "axios";
import { Api_Error_Msg} from "../config/constant";
import { LeadConfirmation } from "../services/LeadConfirmation";
import {mrhs3upload, startLoader } from "..";
import { fspLeadFlow } from "../scheduler/fsp-os-flow/fsp-pre-filldata-remove";
import { onlineCommonLeadFlow } from "../scheduler/common-os/common-progress-bar";
import { asvOnlineLeadFlow } from "../scheduler/asv-os-flow/asv-pre-filldata-remove";
import { setLeadOnly } from "../scheduler/setLeadOnly";
import {apiCaching} from "../config/cachingConfig";
import {getCachedAPIResponse} from "../apiCaching/apiWrapper";
let timeSlotsData: TimeSlot[] = [];
let browsingDifferentDatesCount= 0;
export const animation = {
    loaderFlag:false,
    modalFlag:false
}
export const slotIn = {
    slotIndex:undefined as any
}
// export const slotIn: {[slotIndex: string]: any} = {};
export const slotsDate: {
    slotsByDate:any
} = {slotsByDate:[]}
export const apiCall ={
    error:false
}
export async function refreshScheduleData(year: any, month: any)
{
    if ($('#conceptCode').val() == 'MLY')
    {
        $(".mly-service-estimate-screen .calendar-screen").removeClass("d-none");
    }
    defaultEnableCal.changeActive = false;
    $('.calendar-wrapper .next-btn').removeClass('disble-btn').prop('disabled', false);
    slotsDate.slotsByDate = [];
    var isEstimate = localStorage.getItem("selectedAboutHome") == "recurring" ? "true" : "false"
    var isSchedule = $("#brandName").val() == "molly-maid" ? "&IsEstimate=" + isEstimate : "";
    var webLocationId = window.objOSGlobal.requestBodyValues.WebLocationId ? window.objOSGlobal.requestBodyValues.WebLocationId : '8113';
    var serviceSkuId = window.objOSGlobal.requestBodyValues.serviceSkuId
    var getZipCode = localStorage.getItem('zipcode');
    var zipCode = getZipCode ? getZipCode : 43230;
    $('.date-details-wrapper').empty();
    $('.calendar-row').empty();
    localStorage.setItem("estIsLeadOnly", window.objOSGlobal.requestBodyValues.IsLeadOnly)
    var date = new Date();
    var today: any = new Date();
    // if ($('#conceptCode').val() == 'MRA'){
    //     today = new Date(new Date().setDate(new Date().getDate() + 1));
    // }
    date = new Date(date.getFullYear(), date.getMonth(), 1);
    var numberOfDays = 31;
    var dd: any = today.getDate();
    var mm: any = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    today = mm + '/' + dd + '/' + yyyy;
    getBrandJsonValue();
    if (getWeekObj.getNumberOfWeeks != 0)
    {
        numberOfDays = getWeekObj.getNumberOfWeeks * 7;
    } else
    {
        if (year == date.getFullYear() && month == (date.getMonth() + 1))
        {
            numberOfDays = daysInMonth(month, year);
        } else
        {
            numberOfDays = daysInMonth(month, year);
        }
    }
    // if(animation.loaderFlag){
    //     startLoader();
    // }
    //startLoader();
    callBackComponent();
    // if ($('#conceptCode').val() == 'MLY'){
    //     $('.contact-right.mly-callBack .call-txt').attr('href','#');
    // } hide this for bug 161326
    let conceptChecker = $('#conceptCode').val();
    var serviceSkuIDs: any = conceptChecker != 'MRA' ? servieModal.enable ? '' : `&ServiceSkuId=${serviceSkuId}` : `&ServiceSkuId=${serviceSkuId}`;
    let apiUrl = apiConfig.techAvalibility + '?PostalCode=' + zipCode + '&WebLocationId=' + webLocationId + '&NumberOfDays=' + numberOfDays + serviceSkuIDs + '&DateStart=' + today + isSchedule + '&apikey=' + window.DEFINE_VALUE.JS_API_KEY;
    try
    {
        let response = await getCachedAPIResponse(apiUrl, apiCaching.TechAvailability,true,false);
        let results = response;
        if (results?.resultCollection.length != 0)
        {
            let weekdaysOnly = results.resultCollection;
            if ($('#conceptCode').val() == 'MLY')
            {
                $('.saturday-selected-text').addClass('d-none');
                weekdaysOnly = results.resultCollection.filter(function (element: any, index: any)
                {
                    var dt = new Date(element.startDate);
                    return (dt.getDay() != 0);
                });
            }
            // if (animation.loaderFlag){
            //     stopLoader();
            // }
            animation.loaderFlag = false
            localStorage.setItem("scheduleData", JSON.stringify(weekdaysOnly));
            if (weekdaysOnly?.length > 0)
            {
                var currentIndex = weekdaysOnly.find((itm: any) => itm.isAvailable);
            }
            if (currentIndex)
            {
                var resultData = JSON.parse(localStorage.getItem('scheduleData'));
                if (resultData[0].startDate == currentIndex.startDate)
                {
                    $('.calendar-wrapper .prev-btn').addClass('hidden');
                }
                resultData.forEach((element: any) =>
                {
                    var date = element.startDate.split('T')[0]
                    var item = slotsDate.slotsByDate.find((itm: any) => itm.date == date);
                    if (!item)
                    {
                        item = { date, slots: [] }
                        slotsDate.slotsByDate.push(item);
                    }
                    item.slots.push(element)
                });
                slotIn.slotIndex = slotsDate.slotsByDate.findIndex((itm: any) => itm.date == currentIndex.startDate.split('T')[0]);
                dateDisplay(slotIn.slotIndex);
                stopLoader();
            } else
            {
                let { techAvailFailCheck, brand } = checkBrandNameWise();
                if (techAvailFailCheck)
                {
                    apiCall.error = true;
                    if (brand === "mly")
                    {
                        window.onbeforeunload = null;
                        setLeadOnly();
                        stopLoader();
                    } else
                    {
                        leadFlowTechAvailabiltyFail(brand)
                    }
                } else
                {
                    stopLoader();
                    if (!window.objOSGlobal.requestBodyValues.brandJsonGlobalObj[0].opus_call_back_comp_enabled)
                    {
                        apiCall.error = true;
                        $('.tab-pane.fieldset').removeClass('active');
                        $('#steptwo').addClass('active');
                        $('#steptwo .head-title.primary-theme-clr').text('Please call us and we’ll help you find a time that works for you.');
                        $('.progress, .progressbar').hide();
                    }
                }
            }
        } else
        {
            let { techAvailFailCheck, brand } = checkBrandNameWise();
            if (techAvailFailCheck)
            {
                apiCall.error = true;
                leadFlowTechAvailabiltyFail(brand)
            } else
            {
                stopLoader();
                apiCall.error = true;
                $('.tab-pane.fieldset').removeClass('active');
                $('#steptwo').addClass('active');
                $('#steptwo .head-title.primary-theme-clr').text('Please call us and we’ll help you find a time that works for you.');
                $('.progress, .progressbar').hide();
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
            }
        }
    } catch (error)
    {
        animation.loaderFlag = false
        localStorage.removeItem("scheduleData");
        let { techAvailFailCheck, brand } = checkBrandNameWise();
        if (techAvailFailCheck)
        {
            apiCall.error = true;
            leadFlowTechAvailabiltyFail(brand)
            if(brand === "mly") {
              stopLoader();
            }
        } else
        {
            apiCall.error = true;
            stopLoader();
            animation.modalFlag = true;
            if ($('#conceptCode').val() != 'MLY')
            {
                $('.scheduler-slide').css('display', 'none');
            }
            if ($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === 'my-handyman')
            {
                $(".modal-header-one").addClass("d-none");
            }
            $('.result-stack-logo').show();
            $('.scheduler-modal .call-details-text').show();
            $('.scheduler-modal .neighborly-stamp-wrap').css('display', 'block');
            $('.scheduler-modal .modal-header-one').css('display', 'block');
            $('.scheduler-modal .modal-dialog .modal-header .sub-heading').hide();
            if ($("#brandName").val() != "molly-maid")
            {
                $('.col-md-12.order-col-4').append(`<h2 id = "headermsg" class = "moadal-h2-text primary-theme-clr">${Api_Error_Msg}</h2>`)
                $('.col-md-12.order-col-4').after('<div class="close-location align-center-wrap" id = "closebtnwraper"><input type="button" value="Close" id = "modal-btn-close" class="js-next-btn primary-btn next backToServiceBtn modal-leadbtn-close" data-dismiss="modal" aria-label="Close"></div>');
            }
        }
        console.info('Error in fetching TechAvaibility API data:', error);
    }
    /*
    let config = {
        method: 'get',
        url: apiUrl,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    await axios(config)
    .then(function (response:any) {
        let results = response.data;
        if (results?.resultCollection.length != 0){
            let weekdaysOnly = results.resultCollection;
            if ($('#conceptCode').val() == 'MLY'){
                $('.saturday-selected-text').addClass('d-none');
                weekdaysOnly = results.resultCollection.filter(function (element: any, index: any)
                {
                    var dt = new Date(element.startDate);
                    return (dt.getDay() != 0);
                });
            }
            // if (animation.loaderFlag){
            //     stopLoader();
            // }
            animation.loaderFlag = false
            localStorage.setItem("scheduleData", JSON.stringify(weekdaysOnly));
            if (weekdaysOnly?.length > 0){
                var currentIndex = weekdaysOnly.find((itm: any) => itm.isAvailable);
            }
            if (currentIndex){
                var resultData = JSON.parse(localStorage.getItem('scheduleData'));
                if(resultData[0].startDate == currentIndex.startDate){
                    $('.calendar-wrapper .prev-btn').addClass('hidden');
                }
                resultData.forEach((element: any) =>{
                    var date = element.startDate.split('T')[0]
                    var item = slotsDate.slotsByDate.find((itm: any) => itm.date == date);
                    if (!item)
                    {
                        item = { date, slots: [] }
                        slotsDate.slotsByDate.push(item);
                    }
                    item.slots.push(element)
                });
                slotIn.slotIndex = slotsDate.slotsByDate.findIndex((itm: any) => itm.date == currentIndex.startDate.split('T')[0]);
                dateDisplay(slotIn.slotIndex);
                stopLoader();
            } else{
                let {techAvailFailCheck,brand} = checkBrandNameWise();
                if (techAvailFailCheck){
                    apiCall.error = true;
                    if(brand==="mly"){
                    window.onbeforeunload = null; 
                    setLeadOnly();
                    stopLoader();
                    }else {
                    leadFlowTechAvailabiltyFail(brand)
                    }
                }else{
                    stopLoader();
                    if(!window.objOSGlobal.requestBodyValues.brandJsonGlobalObj[0].opus_call_back_comp_enabled) {
                    apiCall.error = true;
                    $('.tab-pane.fieldset').removeClass('active');
                    $('#steptwo').addClass('active');
                    $('#steptwo .head-title.primary-theme-clr').text('Please call us and we’ll help you find a time that works for you.');
                    $('.progress, .progressbar').hide();
                    }
                }
            }
        }else{
                let {techAvailFailCheck,brand} = checkBrandNameWise();
                if (techAvailFailCheck){
                    apiCall.error = true;
                    leadFlowTechAvailabiltyFail(brand)
                }else{
                stopLoader();
                apiCall.error = true;
                $('.tab-pane.fieldset').removeClass('active');
                $('#steptwo').addClass('active');
                $('#steptwo .head-title.primary-theme-clr').text('Please call us and we’ll help you find a time that works for you.');
                $('.progress, .progressbar').hide();
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
            }
        }
    }).catch(function (error:any) {
                animation.loaderFlag = false
                localStorage.removeItem("scheduleData");
                let {techAvailFailCheck,brand} = checkBrandNameWise();
                if (techAvailFailCheck){
                    apiCall.error = true;
                    leadFlowTechAvailabiltyFail(brand)
                }else{
                    apiCall.error = true;
                    stopLoader();
                    animation.modalFlag = true;
                    if ($('#conceptCode').val() != 'MLY')
                    {
                        $('.scheduler-slide').css('display', 'none');
                    }
                    if ($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === 'my-handyman')
                    {
                        $(".modal-header-one").addClass("d-none");
                    }
                    $('.result-stack-logo').show();
                    $('.scheduler-modal .call-details-text').show();
                    $('.scheduler-modal .neighborly-stamp-wrap').css('display', 'block');
                    $('.scheduler-modal .modal-header-one').css('display', 'block');
                    $('.scheduler-modal .modal-dialog .modal-header .sub-heading').hide();
                    if ($("#brandName").val() != "molly-maid")
                    {
                        $('.col-md-12.order-col-4').append(`<h2 id = "headermsg" class = "moadal-h2-text primary-theme-clr">${Api_Error_Msg}</h2>`)
                        $('.col-md-12.order-col-4').after('<div class="close-location align-center-wrap" id = "closebtnwraper"><input type="button" value="Close" id = "modal-btn-close" class="js-next-btn primary-btn next backToServiceBtn modal-leadbtn-close" data-dismiss="modal" aria-label="Close"></div>');
                    }
                }
       })
    */
}

export function checkBrandNameWise(){
    let concepCode  = $('#conceptCode').val()?.toLowerCase();
    switch(concepCode){
        case 'mly':
        return{ techAvailFailCheck:true,brand:'mly'}
        case 'hmc':
        return { techAvailFailCheck:true,brand:'hmc'}
        case 'mhm':
        return { techAvailFailCheck:true,brand:'mhm'}
        case 'mra':
        return { techAvailFailCheck:true,brand:'mra'}
        case 'mrr':
        return { techAvailFailCheck:true,brand:'mrr'}
        case 'asv':
        return { techAvailFailCheck:true,brand:'asv'}
        case 'fsp':
        return { techAvailFailCheck:true,brand:'fsp'}
        default :
        return { techAvailFailCheck:false,brand:''};
    }
}

export async function leadFlowTechAvailabiltyFail(brand:any){
     setLeadOnly();
    //  if(brand==="mly"){
    //      window.onbeforeunload = null;
    //      stopLoader();
    //  }
      if(brand==="hmc" || brand==="mhm"){
         if ($("#file1").length > 0)
         {
             mrhs3upload(function ()
             {
                 LeadConfirmation();
             });
         } else
         {
             LeadConfirmation();
         }
     }
     else if(brand==="mra"){
        $('[href="#step4"]').tab("show");
        window.objOSGlobal.requestBodyValues.Warranty = true;
     }
     else if(brand==='fsp'){
        fspLeadFlow();
     }
     else if(brand==='asv'){
        $('.tab-pane.fieldset').removeClass('active');
        asvOnlineLeadFlow();
     }
     else if(brand==='mrr'){
        $('.tab-pane.fieldset').removeClass('active');
        onlineCommonLeadFlow();
     }
}
export async function refreshFSPCalendar(year: any, month: any)
{
    defaultEnableCal.changeActive = false;
    $('.calendar-wrapper .next-btn').removeClass('disble-btn').prop('disabled', false);
    slotsDate.slotsByDate = [];
    let webLocationId = window.objOSGlobal.requestBodyValues.WebLocationId ? window.objOSGlobal.requestBodyValues.WebLocationId : '';
    let serviceSkuId = window.objOSGlobal.requestBodyValues.serviceSkuId
    let getZipCode = localStorage.getItem('zipcode');
    let zipCode = getZipCode;
    $('.date-details-wrapper').empty();
    $('.calendar-row').empty();
    localStorage.setItem("estIsLeadOnly", window.objOSGlobal.requestBodyValues.IsLeadOnly)
    let today: any = new Date();
    let numberOfDays;
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) { dd = '0' + dd; }
    if (mm < 10) { mm = '0' + mm; }
    today = mm + '/' + dd + '/' + yyyy;
    getBrandJsonValue();
    if (getWeekObj.getNumberOfWeeks != 0)
    {
        numberOfDays = getWeekObj.getNumberOfWeeks * 7;
    } else
    {
        numberOfDays = daysInMonth(month, year);
    }
    callBackComponent();
    let serviceSkuIDs: any = servieModal.enable ? '' : `&ServiceSkuId=${serviceSkuId}`;
    let apiUrl = apiConfig.techAvalibility + '?PostalCode=' + zipCode + '&WebLocationId=' + webLocationId + '&NumberOfDays=' + numberOfDays + serviceSkuIDs + '&DateStart=' + today + '&apikey=' + window.DEFINE_VALUE.JS_API_KEY;
    try
    {
        let response = await getCachedAPIResponse(apiUrl, apiCaching.TechAvailability, true, false);
        let results = response;
        timeSlotsData = results?.resultCollection;
        renderCalendar();
        if (results?.resultCollection.length != 0)
        {
            let weekdaysOnly = results.resultCollection;
            animation.loaderFlag = false
            localStorage.setItem("scheduleData", JSON.stringify(weekdaysOnly));
            let currentIndex: any = null;
            if (weekdaysOnly?.length > 0)
            {
                currentIndex = weekdaysOnly.find((itm: any) => itm.isAvailable);
            }
            if (currentIndex)
            {
                let resultData = JSON.parse(localStorage.getItem('scheduleData'));
                if (resultData[0].startDate == currentIndex.startDate)
                {
                    $('.calendar-wrapper .prev-btn').addClass('hidden');
                }
                resultData.forEach((element: any) =>
                {
                    let date = element.startDate.split('T')[0]
                    let item = slotsDate.slotsByDate.find((itm: any) => itm.date == date);
                    if (!item)
                    {
                        item = { date, slots: [] }
                        slotsDate.slotsByDate.push(item);
                    }
                    item.slots.push(element)
                });
                slotIn.slotIndex = slotsDate.slotsByDate.findIndex((itm: any) => itm.date == currentIndex.startDate.split('T')[0]);
                dateDisplay(slotIn.slotIndex);
                stopLoader();
            } else
            {
                let { techAvailFailCheck, brand } = checkBrandNameWise();
                if (techAvailFailCheck)
                {
                    apiCall.error = true;

                    leadFlowTechAvailabiltyFail(brand)

                } else
                {
                    stopLoader();
                    if (!window.objOSGlobal.requestBodyValues.brandJsonGlobalObj[0].opus_call_back_comp_enabled)
                    {
                        apiCall.error = true;
                        $('.tab-pane.fieldset').removeClass('active');
                        $('#steptwo').addClass('active');
                        $('#steptwo .head-title.primary-theme-clr').text('Please call us and we’ll help you find a time that works for you.');
                        $('.progress, .progressbar').hide();
                    }
                }
            }
        } else
        {
            let { techAvailFailCheck, brand } = checkBrandNameWise();
            if (techAvailFailCheck)
            {
                apiCall.error = true;
                leadFlowTechAvailabiltyFail(brand)
            } else
            {
                stopLoader();
                apiCall.error = true;
                $('.tab-pane.fieldset').removeClass('active');
                $('#steptwo').addClass('active');
                $('#steptwo .head-title.primary-theme-clr').text('Please call us and we’ll help you find a time that works for you.');
                $('.progress, .progressbar').hide();
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
            }
        }
    } catch (error)
    {
        animation.loaderFlag = false
        localStorage.removeItem("scheduleData");
        let { techAvailFailCheck, brand } = checkBrandNameWise();
        if (techAvailFailCheck)
        {
            apiCall.error = true;
            leadFlowTechAvailabiltyFail(brand)
        } else
        {
            apiCall.error = true;
            stopLoader();
            animation.modalFlag = true;

            $('.scheduler-slide').css('display', 'none');

            $('.result-stack-logo').show();
            $('.scheduler-modal .call-details-text').show();
            $('.scheduler-modal .neighborly-stamp-wrap').css('display', 'block');
            $('.scheduler-modal .modal-header-one').css('display', 'block');
            $('.scheduler-modal .modal-dialog .modal-header .sub-heading').hide();

            $('.col-md-12.order-col-4').append(`<h2 id = "headermsg" class = "moadal-h2-text primary-theme-clr">${Api_Error_Msg}</h2>`)
            $('.col-md-12.order-col-4').after('<div class="close-location align-center-wrap" id = "closebtnwraper"><input type="button" value="Close" id = "modal-btn-close" class="js-next-btn primary-btn next backToServiceBtn modal-leadbtn-close" data-dismiss="modal" aria-label="Close"></div>');

        }
        console.info('Error in fetching TechAvaibility API data:', error);
    }
}
type Nullable<T> = T | null;

type TimeSlot = {
    $id: string;
    startDate: string;
    endDate: string;
    displayText: string;
    isAvailable: boolean;
    reasonForUnavailability: string | null;
    scheduleID: string;
};

let currentDate: Date = new Date();
let selectedDate: Nullable<Date> = null;
let selectedTime: Nullable<string> = null;


function renderCalendar(): void {
    updateMonthYear();
    const daysContainer = document.getElementById("days");
    if (!daysContainer) return;
    
    daysContainer.innerHTML = "";
    
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    const totalDays = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const today = new Date();
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 1);

    for (let i = 0; i < firstDay; i++) {
        daysContainer.appendChild(document.createElement("div")); // Empty cell for alignment
    }
    
    for (let day = 1; day <= totalDays; day++) {
        const dayCell = document.createElement("div");
        const currentLoopDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
        const hasAvailableSlot = timeSlotsData.some(slot => new Date(slot.startDate).toDateString() === currentLoopDate.toDateString());

        dayCell.classList.add("day");
        dayCell.textContent = day.toString();

        if (selectedDate?.toDateString() === currentLoopDate.toDateString()) dayCell.classList.add("selected");
        if (currentLoopDate.toDateString() === today.toDateString()) dayCell.classList.add("today");
        if (currentLoopDate < today || currentLoopDate > maxDate || !hasAvailableSlot) {
            dayCell.classList.add("disabled");
        } else {
            dayCell.onclick = () => selectDate(currentLoopDate);
        }
        
        daysContainer.appendChild(dayCell);
    }
    
    toggleMonthButtons();
}

function toggleMonthButtons(): void {
    const prevBtn = document.querySelector<HTMLButtonElement>(".sac-calender-prev.sac-calender-arrows");
    const nextBtn = document.querySelector<HTMLButtonElement>(".sac-calender-next.sac-calender-arrows");
    const today = new Date();
    const maxDate = new Date(today);
    maxDate.setMonth(today.getMonth() + 1);

    if (prevBtn) prevBtn.disabled = currentDate.getFullYear() === today.getFullYear() && currentDate.getMonth() === today.getMonth();
    if (nextBtn) nextBtn.disabled = currentDate >= maxDate;
}

function updateMonthYear(): void {
    const monthYearElement = document.getElementById("month-year");
    if (monthYearElement) {
        monthYearElement.textContent = currentDate.toLocaleString('en-US', { month: 'long', year: 'numeric' });
    }
}

document.querySelector(".sac-calender-prev.sac-calender-arrows")?.addEventListener("click", () => {
    if (currentDate.getMonth() > new Date().getMonth()) {
        currentDate.setMonth(currentDate.getMonth() - 1);
        renderCalendar();
    }
});

document.querySelector(".sac-calender-next.sac-calender-arrows")?.addEventListener("click", () => {
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 2);
    if (currentDate < maxDate) {
        currentDate.setMonth(currentDate.getMonth() + 1);
        renderCalendar();
    }
});

function selectDate(date: Date): void {
    selectedDate = date;
    $('.date-selected').removeClass('hidden');
    const selectedDateElement = document.getElementById("selected-date");
    if (selectedDateElement)
    {
        const formattedDate = new Date(date).toLocaleDateString('en-US', { 
            weekday: 'long', 
            month: 'long', 
            day: 'numeric' 
        });
        selectedDateElement.textContent = formattedDate;
    }
    localStorage.setItem("selectedDate", date.toString());
    if (browsingDifferentDatesCount > 1) {
        $(".sac-tab-pane .calender-cant-find").show();
      }
      browsingDifferentDatesCount++;
      $('.date-unselect').hide();
        renderCalendar();
        renderTimeSlots();
}

function selectTime(timeSlot: HTMLButtonElement): void {
    selectedTime = timeSlot.dataset.time || null;
    const scheduleId = timeSlot.dataset.schduleId ?? null;
    const selectedDate = timeSlot.dataset.selecteddate
    ||null;  
    if (scheduleId) {
        window.objOSGlobal.requestBodyValues.ScheduleID = scheduleId;
    }
    if(selectedDate){
        window.objOSGlobal.paramsUI.selectedDate = selectedDate;
    }
    sessionStorage.setItem('CTime', selectedTime);
    updateAppointmentInfo();
}

function renderTimeSlots(): void {
    const timeSlotsContainer = document.getElementById("time-slots");
    if (!timeSlotsContainer) return;
    timeSlotsContainer.innerHTML = "";

    if (!selectedDate) return;

    const filteredSlots = timeSlotsData.filter(slot => new Date(slot.startDate).toDateString() === selectedDate.toDateString());
    
    if (filteredSlots.length === 0) {
        timeSlotsContainer.innerHTML = "<p>No available time slots</p>";
        return;
    }

    filteredSlots.forEach(slot => {
        const timeSlot = document.createElement("button");
        timeSlot.classList.add("sac-calendar-btn");
        if (!slot.isAvailable) timeSlot.classList.add("disabled");

        timeSlot.dataset.isAvailable = String(slot.isAvailable);
        timeSlot.dataset.selecteddate = slot.startDate;
        timeSlot.dataset.time = slot.displayText;
        timeSlot.dataset.schduleId = slot.scheduleID;
        timeSlot.textContent = slot.displayText;

        if (slot.isAvailable) timeSlot.onclick = () => selectTime(timeSlot);
        timeSlotsContainer.appendChild(timeSlot);
    });
}

function updateAppointmentInfo(): void {

    const appointmentInfoElement = document.getElementById("appointment-info");
    if (!appointmentInfoElement || !selectedDate || !selectedTime) return;
    $('.calender-selected-time-wrap.appointment-info').removeClass('hidden');
    $('.calendar-screen.active .scheduler-btn.primary-btn.sac-next').prop("disabled", false);
    const formattedDate = selectedDate.toLocaleString('en-US', { weekday: 'long', month: 'long', day: 'numeric' });
    appointmentInfoElement.textContent = `${formattedDate}, ${selectedTime}`;
}

$(document).on("click", ".btn-cant-find", function () {
    startLoader();
    setTimeout(function () {
        window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
        window.objOSGlobal.requestBodyValues.ScheduleID = '';
        LeadConfirmation();
      $(".sac-next").addClass("disabled");
    }, 300);
  });
